import React, { useState, useEffect } from 'react';
import './App.css';
import { FaClock, FaRegCreditCard, FaUserTie, FaUsers } from 'react-icons/fa';

function App() {
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [users, setUsers] = useState({ developers: [], scrumMaster: null, spectators: [] });
  const [isRevealed, setIsRevealed] = useState(false);

  const cards = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '?'];

  useEffect(() => {
    if (isLoggedIn) {
      fetchUsers();
      const interval = setInterval(fetchUsers, 5000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`/api/get_votes.php?reveal=${isRevealed}`);
      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      }
    } catch (error) {
      console.error('Error fetching users and votes:', error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (userName && role) {
      try {
        const response = await fetch('/api/join_session.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_name: userName, role }),
        });
        const data = await response.json();
        if (data.success) {
          setIsLoggedIn(true);
        } else {
          alert('Failed to join: ' + data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while joining');
      }
    } else {
      alert('Please enter your name and select a role');
    }
  };

  const submitVote = async (card) => {
    if (role !== 'Developer') return;
    try {
      const response = await fetch('/api/submit_vote.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name: userName, vote: card }),
      });
      const data = await response.json();
      if (data.success) {
        setSelectedCard(card);
        fetchUsers();
      } else {
        alert('Failed to submit vote');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your vote');
    }
  };

  const revealVotes = async () => {
    setIsRevealed(true);
    fetchUsers();
  };

  const resetVotes = async () => {
    try {
      const response = await fetch('/api/reset_votes.php', {
        method: 'POST',
      });
      const data = await response.json();
      if (data.success) {
        setIsRevealed(false);
        setSelectedCard(null);
        fetchUsers();
      } else {
        alert('Failed to reset votes');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while resetting votes');
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="App">
        <h1>Scrum Poker</h1>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Your Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="">Select Role</option>
            <option value="Developer">Developer</option>
            <option value="Scrum Master">Scrum Master</option>
            <option value="Spectator">Spectator</option>
          </select>
          <button type="submit">Join Session</button>
        </form>
      </div>
    );
  }

  return (
    <div className="App">
      <h1>Welcome to Scrum Poker, {userName}!</h1>
      
      <div className="voting-block">
        <h2><FaUsers /> Voting Block</h2>
        <div className="developers-list">
          {users.developers.map((dev, index) => (
            <div key={index} className="developer">
              {dev.user_name}
              {dev.vote === null ? <FaClock /> : 
 				(isRevealed ? <span className="vote">{dev.vote}</span> : <FaRegCreditCard />)}
            </div>
          ))}
        </div>
      </div>

      <div className="scrum-master">
        <h2><FaUserTie /> Scrum Master</h2>
        <div>{users.scrumMaster ? users.scrumMaster.user_name : 'Unassigned'}</div>
      </div>

      <div className="spectators">
        <h2>Spectators</h2>
        {users.spectators.map((spectator, index) => (
          <div key={index}>{spectator.user_name}</div>
        ))}
      </div>

      {role === 'Developer' && (
        <div className="card-container">
          {cards.map(card => (
            <button
              key={card}
              className={`card ${selectedCard === card ? 'selected' : ''}`}
              onClick={() => submitVote(card)}
            >
              {card}
            </button>
          ))}
        </div>
      )}

      {role === 'Scrum Master' && (
        <div className="control-buttons">
          <button onClick={revealVotes} disabled={isRevealed}>Reveal Votes</button>
          <button onClick={resetVotes}>Reset Votes</button>
        </div>
      )}
    </div>
  );
}

export default App;